import Util from './util';
import PhysicalNode from './physical_node';
import PhysicalLeaf from './physical_leaf';
import TableP5 from './TableP5';
import p5 from 'p5';

class PhysicalCluster extends PhysicalNode {
  _nodes: PhysicalNode[];

  constructor(center: p5.Vector) {
    super(center);
    this._nodes = [];
  }

  /**
   * @param {PhysicalNode} node the node to add
   * @throws {String} Message about bad in parameters
   * @throws {String} Message about trying to readd node
   * @return {SUCCESS | FAILURE}
   */
  addNode(node: PhysicalNode) {
    if (!node) throw 'PhysicalCluster.addNode called without parameter';
    if (!(node instanceof PhysicalNode)) {
      throw 'PhysicalCluster.addNode called with something else than a PhysicalNode';
    }

    const index = this._nodes.indexOf(node);

    if (index !== -1)
      throw "Trying to add a node that's already in the cluster";

    this._nodes.push(node);
    return Util.SUCCESS;
  }

  /**
   * @param {PhysicalNode} node the node to remove
   * @throws {String} Message about bad in parameters
   * @throws {String} Message about trying to remove something that is not here
   * @return {SUCCESS | FAILURE}
   */
  removeNode(node: PhysicalNode) {
    if (!node) throw 'Call to removeNode without parameter';
    if (!(node instanceof PhysicalNode)) {
      throw 'Call to removeNode with something else than a PhysicalNode';
    }

    // All should be in here....
    const index = this._nodes.indexOf(node);

    if (index === -1)
      throw "Trying to remove a node that's not in the cluster!";

    this._nodes.splice(index, 1);
    return Util.SUCCESS;
  }

  /**
   * Get the Node at index
   *
   * @param {int} index - the position to get Node at
   * @return PhysicalNodes
   */
  get(index: number): PhysicalNode {
    return this._nodes[index];
  }

  clearNodes() {
    this._nodes.length = 0;
  }

  lengthOfLeaves() {
    let length = 0;

    for (let nodeIndex in this._nodes) {
      const node = this._nodes[nodeIndex];

      length += node.lengthOfLeaves ? node.lengthOfLeaves() : 1;
    }
    return length;
  }

  length() {
    return this._nodes.length;
  }

  find(cb: (node: PhysicalNode) => boolean) {
    return this._nodes.find(cb);
  }

  forEach(cb: (node: PhysicalNode) => void) {
    this._nodes.forEach(cb);
  }

  some(cb: (node: PhysicalNode) => boolean) {
    return this._nodes.some(cb);
  }

  getAllChildren() {
    return this._nodes.slice(0);
  }

  findByTUIOID(id: number) {
    return this._nodes.find((node) => node.tuioId === id);
  }

  getAllLeaves() {
    let leaves: PhysicalLeaf[] = [];

    for (let nodeIndex in this._nodes) {
      const node = this._nodes[nodeIndex];

      if (node instanceof PhysicalLeaf) {
        leaves.push(node);
      } else if (node instanceof PhysicalCluster) {
        leaves = leaves.concat(node.getAllLeaves());
      }
    }
    return leaves;
  }

  getFirstLeaf() {
    const firstNode = this.find(
      (cb) => cb instanceof PhysicalLeaf || cb instanceof PhysicalCluster
    );

    if (firstNode instanceof PhysicalLeaf || firstNode === undefined) {
      return firstNode;
    } else if (firstNode instanceof PhysicalCluster) {
      return firstNode.getFirstLeaf();
    } else {
      return firstNode;
    }
  }

  executePersistentConnection() {
    for (const node of this._nodes) {
      node.executePersistentConnection();
    }
  }

  resetConnections() {
    for (const node of this._nodes) {
      node.resetConnections();
    }
  }

  layout(p5Instance: TableP5) {
    for (const node of this._nodes) {
      node.layout(p5Instance);
    }
  }

  drawBg(p5Instance: TableP5) {
    for (const node of this._nodes) {
      node.drawBg(p5Instance);
    }
  }

  draw(p5Instance: TableP5) {
    for (const node of this._nodes) {
      node.draw(p5Instance);
    }
  }

  mouseReleased(x: number, y: number) {
    for (const node of this._nodes) {
      if (node.buttons.mouseReleased(x, y)) {
        return true;
      }
    }

    return false;
  }

  mouseDragged(x: number, y: number) {
    for (const node of this._nodes) {
      if (node.buttons.mouseDragged(x, y)) {
        return true;
      }
    }

    return false;
  }

  mousePressed(x: number, y: number) {
    for (const node of this._nodes) {
      if (node.buttons.mousePressed(x, y)) {
        return true;
      }
    }

    return false;
  }

  touchStarted(touch, touchedCallback = () => {}) {
    for (const node of this._nodes) {
      if (node.buttons.touchStarted(touch, touchedCallback)) {
        return true;
      }
    }

    return false;
  }

  refreshCurrentEnergyNeeds() {
    for (const node of this._nodes) {
      node.refreshCurrentEnergyNeeds();
    }
  }
}

export default PhysicalCluster;
