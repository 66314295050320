import Util from './util';
import Connection from './energy_connection';
import {
  EnergyNeedAndPotentialElectricityType,
  EnergyNeedType
} from 'js/Table/data/data_manager';
import ElectricityNode from './electricity_node';

let idCounter = 0;

/**
 * The Abstract part connecting the pipes
 * @class EnergyNode
 */
export default class EnergyNode {
  connections: Connection[];
  id: number;
  dead: boolean;
  outsideGrid: boolean;
  _heatingNeedMW: number;
  _coolingNeedMW: number;
  _scale: number;
  historic: EnergyNeedAndPotentialElectricityType[];
  predictive: EnergyNeedAndPotentialElectricityType[];
  heatingElectricityCostMW: number;
  coolingElectricityCostMW: number;
  electricityNode?: ElectricityNode;

  /**
   * @param {number} heating the heating need for the Node
   * @param {number} cooling the cooling need for the Node
   */
  constructor(heating: number, cooling: number) {
    this.connections = [];
    this.outsideGrid = false;
    this.id = idCounter++;

    if (isNaN(heating)) {
      throw 'Trying to initiate EnergyNode without heat need value';
    }
    if (isNaN(cooling)) {
      throw 'Trying to initiate EnergyNode without cool need value';
    }

    this._heatingNeedMW = heating;
    this._coolingNeedMW = cooling;

    this._scale = 0.5;
  }

  updateEnergyNeeds(heating: number, cooling: number) {
    this._heatingNeedMW = heating;
    this._coolingNeedMW = cooling;
  }

  destructor() {
    this.dead = true;
  }

  /**
   * @param {EnergyNode} otherNode the node to connect to
   * @throws {String} Message about dead node
   * @throws {String} Message about bad in parameters
   * @return {SUCCESS | FAILURE}
   */
  connectTo(otherNode: EnergyNode) {
    if (this.dead) throw 'Trying to connect to dead EnergyNode';
    if (!otherNode)
      throw 'Trying to connect EnergyNode without Other parameter';
    if (!(otherNode instanceof EnergyNode)) {
      throw 'EnergyNode, trying to connect to something that is not an energy node!';
    }

    const connection = new Connection(this, otherNode);

    this.connections.push(connection);
    otherNode.connections.push(connection);

    return Util.SUCCESS;
  }

  getScale() {
    return this._scale;
  }

  /**
   * @param {number} scale energy scale in the range [0..1]
   * @throws {String} Message about bad in-parameter
   */
  setScale(scale) {
    if (isNaN(scale)) throw 'Trying to set scale without number';
    if (scale < 0 || scale > 1) throw 'Trying to set scale outside of range';

    this._scale = scale;
  }

  /**
   * @throws {String} Message about broken node
   */
  getTotalEnergySize() {
    if (this.dead) throw 'Trying to fetch heat value from dead energynode';
    if (isNaN(this._heatingNeedMW) || isNaN(this._coolingNeedMW)) {
      throw 'WARNING: Trying to fetch value from broken energynode';
    }

    return (this._heatingNeedMW + this._coolingNeedMW) * this._scale;
  }

  /**
   * @throws {String} Message about dead node
   */
  getHeatingNeedMW() {
    if (this.dead) throw 'Trying to fetch heat value from dead energynode';

    return this._heatingNeedMW * this._scale;
  }

  setHistoric(historic: EnergyNeedAndPotentialElectricityType[]) {
    this.historic = historic;
  }

  setPredictive(predictive: EnergyNeedAndPotentialElectricityType[]) {
    this.predictive = predictive;
  }

  _scaled(array: EnergyNeedAndPotentialElectricityType[]): EnergyNeedType[] {
    const _scaledArray: EnergyNeedType[] = [];

    for (let i = 0; i < array.length; i++) {
      _scaledArray.push({
        time: array[i].time,
        heatingNeedMW: array[i].heatingNeedMW * this._scale,
        coolingNeedMW: array[i].coolingNeedMW * this._scale,
        electricityGenerationMW:
          array[i].electricityPotential *
          (this.electricityNode?.getMaxEffectMW() ?? 0)
      });
    }
    return _scaledArray;
  }

  getHistoric() {
    if (!this.historic) return [];
    return this._scaled(this.historic);
  }

  getPredictive() {
    if (!this.predictive) return [];
    return this._scaled(this.predictive);
  }

  getCoolingNeedMW() {
    return this._coolingNeedMW * this._scale;
  }

  getRatio() {
    let heatRatio: number;
    let coolRatio: number;

    if (this._heatingNeedMW === 0 && this._coolingNeedMW === 0) {
      heatRatio = 0;
      coolRatio = 0;

      return undefined;
    } else if (this._heatingNeedMW === 0) {
      heatRatio = 0;
      coolRatio = 1;
    } else if (this._coolingNeedMW === 0) {
      heatRatio = 1;
      coolRatio = 0;
    } else {
      heatRatio =
        this._heatingNeedMW / (this._heatingNeedMW + this._coolingNeedMW);
      coolRatio = 1 - heatRatio;
    }

    return {
      heatRatio,
      coolRatio
    };
  }

  getConnectionToNode(otherNode: EnergyNode) {
    for (let i = this.connections.length - 1; i >= 0; i--) {
      const connection = this.connections[i];

      if (connection.from === otherNode || connection.to === otherNode) {
        return connection;
      }
    }
    return undefined;
  }

  /**
   * @param {Connection} connection the Connection to connect from
   * @throws {String} Message about bad in parameters
   * @throws {String} Message about wrong connection
   * @return {SUCCESS | FAILURE}
   */
  disConnect(connection: Connection) {
    if (!(connection instanceof Connection)) {
      throw 'EnergyNode, trying to disconnect from something that is not a connection';
    }

    const connectionIndex = this.connections.indexOf(connection);

    if (connectionIndex === -1) {
      throw 'EnergyNode, trying to disconnect from connection that was not connected!';
    }

    this.connections.splice(connectionIndex, 1);
    return Util.SUCCESS;
  }

  /**
   * @throws {String} Message about dead node
   */
  reset() {
    if (this.dead) throw 'Trying to reset dead energynode';

    this.connections.forEach((connection) => {
      const otherNode = connection.getNode(this);

      otherNode.disConnect(connection);
      connection.reset();
    });
    this.connections = [];
    return Util.SUCCESS;
  }
}
