import React, { useSyncExternalStore } from 'react';
import classnames from 'classnames';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import './PowerProductionView.css';
import EctotableDashboardPanel from '../DashboardView/EctotableDashboardPanel';
import dashboardData from './PowerProductionDashboardData.json';
import EnergyDemandBarChart from 'js/views/PowerConsumptionView/EnergyDemandBarChart';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import Box from 'js/components/Box/Box';
import T from 'ecto-common/lib/lang/Language';
import { EnergyData } from 'js/Table/data_transmitter';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

type PowerProductionViewProps = {
  data: EnergyData;
  className?: string;
  ectocloudMode: boolean;
  size: { width: number; height: number };
};

/** Client screen showing power production. */
const PowerProductionView = ({
  data,
  className,
  ectocloudMode,
  size
}: PowerProductionViewProps) => {
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );

  if (ectocloudMode) {
    return (
      <div className="PowerProductionView__dashboard_content__main">
        <div className="View__title">
          <h1>Live data</h1>
        </div>
        <div className="PowerProductionView__dashboard_panel_container">
          <EctotableDashboardPanel
            panelData={dashboardData}
            contentClassName="PowerProductionView__dashboard_panel"
            showOnlyContent
          />
        </div>
      </div>
    );
  }

  return (
    <View
      direction="column"
      className={classnames(className)}
      title="Energy Source Balance"
    >
      <div className="PowerProductionView__content__top">
        {data != null && (
          <Box title="KPI:s">
            <div className="PowerProductionView__content__kpis">
              <Flex direction="row">
                <div>
                  <strong>Building demands</strong>
                  <ul>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.heatcapacity}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingDemandHeatPeakKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.coolingcapacity}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingDemandCoolPeakKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.heatdemand}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingDemandHeatTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.coolingdemand}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingDemandCoolTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                  </ul>
                  <br />
                  <strong>Energy center loads</strong>

                  <ul>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.heatloaddemand}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.ehLoadHeatTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.coolingloaddemand}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.ehCoolTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {
                          T.ectoplanner.buildingsummary
                            .balancedloadsbetweenbuildings
                        }
                        :{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingsBalancedLoadMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                  </ul>
                </div>
                <div>
                  <strong>Building imports</strong>
                  <ul>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.heatimportcapacity}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportHeatPeakKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.coolingimportcapacity}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportCoolPeakKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.importedheat}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportHeatTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.importedcooling}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportCoolTotMWha,
                        T.ectoplanner.units.mwha,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {
                          T.ectoplanner.buildingsummary
                            .electricityimportcapacity
                        }
                        :{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportElecPeakKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                    <li>
                      <label>
                        {T.ectoplanner.buildingsummary.importedelectricity}:{' '}
                      </label>
                      {formatNumberUnit(
                        data.buildingImportElecTotKW,
                        T.ectoplanner.units.kw,
                        0
                      )}
                    </li>
                  </ul>
                </div>
              </Flex>
            </div>
          </Box>
        )}
      </div>

      <Flex direction="column" className="PowerProductionView__content__main">
        {size.width != null && size.height != null && (
          <EnergyDemandBarChart
            darkModeEnabled={darkModeEnabled}
            data={data}
            size={size}
          />
        )}
      </Flex>
    </View>
  );
};

export default React.memo(PowerProductionView);
