// Settings
const fps = 60;

export type EctotableLocation = {
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  fallbackConsumption: number;
  debugColor: string;
  airTempFile: string;
  cityDataFile: string;
  weatherStationId: string;
};

export default {
  fps,
  backgroundColor: '#141414',
  selectedColor: 'rgb(90, 180, 90)',
  structureStrokeColor: 'rgb(200, 200, 200)',
  structureFontColor: 'rgb(255, 255, 255)',
  structureFontSize: 14,
  structureHeaderFontSize: 15,
  structureFont: 'Helvetica',
  structureSize: 250,
  structureDialSize: 150,
  baseStructureSize: 40,
  structureAnimationTime: fps * 1.5, // the time over which the building animation run, expressed in frames
  structureImageSize: 100,
  accTankArrowImageSize: 35,
  batterySourceImageSize: 70,
  pvCellSourceImageSize: 100,
  moveThreshold: 10, // below this movement the structure won't react. This is to prevent unwanted jitter
  cornerRadius: 10,
  maxTriangleSize: 1200, // in pixels. Don't recognize triangles larger than this as building

  locationButton: {
    fontSize: 30,
    helpFontSize: 17,
    lineHeight: 30,
    horisontalPadding: 30,
    verticalGutter: 15,
    width: 395,
    height: 80,
    contractedHeight: 140,
    backgroundAlpha: 0.8
  },

  // the higher this is, the less sensitive the program is to losing touch points temporarily
  // the higher it is, the longer it also takes for a building to be erased after removing it
  touchPointErrorTolerance: 500,

  gridColor: '#828282',
  gridDotDistance: 30,

  coldColor: '#1EA2B1',
  warmColor: '#EA1D04',
  inactiveColor: 'rgb(50, 50, 50)',

  coldPipeColor: '#1EA2B1',
  warmPipeColor: '#EA1D04',
  electricityPipeColor: '#CCBB23',

  basePipeColor: 'rgb(150,150,150)',
  basePipeOpacity: 0.5,

  pipeDistance: 10,

  colorAlpha: 150, // use these global variables for a slightly transparent color

  unreasonablyBigJump: 1, // arbitrary radian big enough to be larger than what can be expected to rotate in one frame

  pipeRelativeScale: 6,

  places: [
    {
      city: 'Lund',
      country: 'Sweden',
      countryCode: 'SE',
      latitude: 55.70584,
      longitude: 13.19321,
      fallbackConsumption: 35,
      debugColor: '#f00',
      airTempFile: 'lund_airtemp.txt',
      cityDataFile: 'lund_citydata.json',
      weatherStationId: 'SWE_SN_Malmo.AP-Sturup.026360_TMYx.2004-2018'
    },
    {
      city: 'London',
      country: 'United Kingdom',
      countryCode: 'GB',
      latitude: 51.509865,
      longitude: -0.118092,
      fallbackConsumption: 700,
      debugColor: '#0ff',
      airTempFile: 'london_airtemp.txt',
      cityDataFile: 'london_citydata.json',
      weatherStationId:
        'GBR_ENG_London.Wea.Ctr-St.James.Park.037700_TMYx.2004-2018'
    },
    {
      city: 'Los Angeles',
      country: 'USA',
      countryCode: 'US-CA',
      latitude: 34.052235,
      longitude: -118.243683,
      fallbackConsumption: 380,
      debugColor: '#ff0',
      airTempFile: 'losangles_airtemp.txt',
      cityDataFile: 'losangeles_citydata.json',
      weatherStationId: 'USA_CA_Los.Angeles-USC.Campus.722874_TMYx.2004-2018'
    },
    {
      city: 'Münich',
      country: 'Germany',
      countryCode: 'DE',
      latitude: 48.1368745058447,
      longitude: 11.581847791910908,
      fallbackConsumption: 35,
      debugColor: '#f00',
      airTempFile: 'munich_airtemp.txt',
      cityDataFile: 'munich_citydata.json',
      weatherStationId: 'DEU_BY_Munich.AP.108700_TMYx.2004-2018'
    },
    {
      city: 'Utrecht',
      country: 'Netherlands',
      countryCode: 'NL',
      latitude: 51.9607158,
      longitude: 5.050522,
      fallbackConsumption: 700,
      debugColor: '#0ff',
      airTempFile: 'merwede_airtemp.txt',
      cityDataFile: 'merwede_citydata.json'
    }
  ] as EctotableLocation[],

  energyNeedSizeDivisor: 5
};
