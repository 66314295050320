/* eslint no-lone-blocks: 0 */ // --> OFF
import PhysicalLeaf from './physical_leaf';
import Renderable from './renderable';

import Util from './util';
import Settings from './Settings';
import * as DataManager from './data/data_manager';

import EnergyNode from './energy_node';
import Pipe, { calculateWarmAndColdPipePoints } from './Pipe';

import Position2DHistory from './position_2d_history';
import ImageButton from './image_button';
import removeIcon from '../assets/remove.svg';
import * as d3 from 'd3-ease';
import SliderButton from './slider_button';
import buildingIcons from '../config/buildingIcons';

import { TableContextType } from 'js/Table/context';
import TableP5 from 'js/Table/TableP5';
import { circleNodeTextInset, drawNodeText } from './StructureDrawUtils';
import p5 from 'p5';
import PhysicalNode from 'js/Table/physical_node';

/**
 * Objects that are created when a normal physical base is put down on the screen
 * @class Structure
 */
class Structure extends PhysicalLeaf {
  size: number;
  imageSize: number;
  sliderButton: SliderButton;
  image: any;
  closeButton: ImageButton;
  cache: {
    [key: string]: any;
  };
  p5Instance: TableP5;
  buildingTemplate: any;
  scale: number;
  name: string;
  context: TableContextType;
  baseScale: number;
  getRotationAngle: () => number;
  rotationOffset: number;
  centerOffset: any;
  centerHistory: Position2DHistory;
  icon: string;
  templateScale: number;
  dirtySince: number;
  dead: boolean;
  warmPipe: Pipe;
  coldPipe: Pipe;
  createdTimeStamp: number;
  templateFingerPrint: number;
  touchTriangle: any;
  moveThreshold: number;
  fixedSize: boolean;

  constructor(
    context: TableContextType,
    p5Instance: TableP5,
    buildingTemplate: any,
    scale: number,
    _city: string,
    imageCache: Record<string, p5.Image>,
    onScaleChanged: (item: PhysicalNode) => void
  ) {
    super(null);
    this.active = false;
    this.size = buildingTemplate.scale;
    this.imageSize = Settings.structureImageSize;

    this.sliderButton = new SliderButton((newSize) => {
      this.size = newSize;
      this.cache = {};
      this.node.setScale(newSize);
      this.touch();
      onScaleChanged(this);
    }, this.size);

    if (
      buildingTemplate.icon === buildingIcons.buildingIconConstants.ectogrid
    ) {
      this.image = null;
    } else {
      this.image = p5Instance.loadImage(
        buildingIcons.buildingIcons[buildingTemplate.icon]
      );
    }

    this.sliderButton.minValue = 0.1;
    this.closeButton = new ImageButton(removeIcon, null);
    this.closeButton.setDefault({
      x: 500,
      y: 500,
      width: 50,
      height: 50
    });

    this.closeButton.addListener(() => {
      p5Instance.App?._removeTouchStructure(this);
    });

    this.buttons.buttons = [this.closeButton, this.sliderButton];

    this.buttons.renderInit(p5Instance, imageCache);
    this.name = 'Structure';
    this.context = context;
    Renderable.implement(this, context);

    this.buildingId = buildingTemplate.buildingId;
    this.tuioId = buildingTemplate.tuioId;
    this.validTUIOObject = true;
    this.cache = {};

    this.baseScale = scale;

    // this is to track rotation. We save the angle of rotation at the start
    // so that we can know how much the user then actually turned it
    this.getRotationAngle = () => 0;
    this.rotationOffset = 0;
    this.reportedRotation = 0;

    const { heatingNeedMW, coolingNeedMW } = DataManager.getCurrentNeedFor(
      buildingTemplate.buildingId
    );

    this.centerOffset = buildingTemplate.centerOffset;
    this.centerHistory = new Position2DHistory(context);

    this.icon = buildingTemplate.icon;
    this.name = buildingTemplate.name;

    this.node = new EnergyNode(heatingNeedMW, coolingNeedMW);
    this.node.setScale(buildingTemplate.scale);
    this.templateScale = buildingTemplate.scale;
    this.dirtySince = this.context.appNow();

    this.node.setHistoric(
      DataManager.getHistoricNeedFor(buildingTemplate.buildingId, 24)
    );
    this.node.setPredictive(
      DataManager.getPredictiveNeedFor(buildingTemplate.buildingId, 24)
    );

    this.p5Instance = p5Instance;
    this.createdTimeStamp = p5Instance.frameCount;
    this.templateFingerPrint = buildingTemplate.fingerprint;
  }

  refreshCurrentEnergyNeeds() {
    const { heatingNeedMW, coolingNeedMW } = DataManager.getCurrentNeedFor(
      this.buildingId
    );

    this.node.updateEnergyNeeds(heatingNeedMW, coolingNeedMW);
  }

  destructor() {
    this.dead = true;
    this.cache = {};
    this.node.destructor();
    this.node = undefined;
  }

  /**
   * @param {EnergyNode} structure the Structure to connect to
   * @throws {String} Message about dead node
   */
  connectTo(structure: Structure) {
    if (this.dead) throw 'Trying to access dead Structure';

    this.touch();

    if (super.connectTo(structure) === Util.SUCCESS) {
      structure.previous.push(this);

      // Also ask the abstract node object to connect ot it's counter part
      if (structure.node !== undefined) {
        this.node.connectTo(structure.node);
      }

      return Util.SUCCESS;
    }
  }

  getEnergyNode() {
    return this.node;
  }

  touch() {
    this.dirtySince = this.context.appNow();
  }

  /**
   * @throws {String} Message about dead node
   */
  dataIsDirtySince(marker) {
    if (this.dead) throw 'Trying to access dead Structure';

    return marker < this.dirtySince;
  }

  /**
   * @throws {String} Message about dead node
   */
  layoutPipes() {
    if (this.dead) throw 'Trying to access dead Structure';

    if (this.next === undefined) {
      return;
    }

    if (isNaN(this.cache.boundaryRadius)) {
      throw 'WARNING: error in Structure Boundary calculation';
    }

    const [warmStartPoint, warmEndPoint, coldStartPoint, coldEndPoint] =
      calculateWarmAndColdPipePoints(
        this.center(),
        this.next.center(),
        this._pipeInterSectRadius(),
        this.next._pipeInterSectRadius(),
        this.context.Vector
      );

    if (this.warmPipe == null) {
      this.warmPipe = new Pipe(
        this.p5Instance,
        warmStartPoint,
        warmEndPoint,
        Settings.warmPipeColor
      );
    }

    if (this.coldPipe == null) {
      this.coldPipe = new Pipe(
        this.p5Instance,
        coldStartPoint,
        coldEndPoint,
        Settings.coldPipeColor
      );
    }

    this.coldPipe.init(
      this.p5Instance,
      coldStartPoint,
      coldEndPoint,
      Settings.coldPipeColor
    );
    this.warmPipe.init(
      this.p5Instance,
      warmStartPoint,
      warmEndPoint,
      Settings.warmPipeColor
    );
  }

  resetConnections() {
    super.resetConnections();

    if (this.node) {
      this.node.reset();
    }
  }

  preparePersistentConnection(otherNode) {
    super.preparePersistentConnection(otherNode);

    this.touch();
  }

  touchTriangleRotationUpdated(newRotation) {
    this.getRotationAngle = () => -newRotation; // We negate this value to get the right rotation direction

    // first get the actual rotation in radians
    let rotation = -newRotation;

    // then divide it to make it slower and add the starting value
    rotation = rotation / 2 + this.templateScale;

    // We want to remember when going outside the limits so if the user dials
    // far above maximum, dialing back should work immediately
    if (rotation < this.rotationOffset) {
      // we're below "zero"
      this.rotationOffset = Math.min(this.rotationOffset, rotation);
    } else if (rotation > this.rotationOffset + 1) {
      // we're above the maximum, which is "zero" + 1
      this.rotationOffset = Math.max(this.rotationOffset, rotation - 1);
    }
    rotation -= this.rotationOffset;

    // Cap it between 0.1 and 1
    this.size = Math.min(Math.max(0.1, rotation), 1);

    this.cache = {};
    this.node.setScale(this.getSize());

    const deltaAngle = Math.abs(newRotation - this.reportedRotation);

    // If the total rotation has not changed that much since last time we used the rotation
    // we just store the new value but do nothing more to avoid network flooding
    if (deltaAngle < 0.02) {
      return;
    }

    // If the structure is being moved around use a higher threshold of rotations to throttle updates
    if (this.centerHistory.getSpeed() > 0.01 && deltaAngle < 0.2) {
      return;
    }

    this.reportedRotation = newRotation;
    this.touch();
  }

  touchTriangleListenerAdded(touchTriangle) {
    this.touch();

    this.touchTriangle = touchTriangle;
  }

  /* Get the "size" of the structure, which is the value scaling value coming from the user rotation */
  getSize() {
    return this.size;
  }

  center() {
    const result = this.centerPos.copy();

    if (this.centerOffset && this.touchTriangle) {
      const offset = this.centerOffset * (this.baseScale || 1);
      const abs = this.touchTriangle.getAbsoluteAngle();

      result.x += Math.cos(abs) * offset;
      result.y += Math.sin(abs) * offset;
    }

    return result;
  }

  /** Expects a P5 3D vector
   */
  touchTriangleCenterUpdated(posCenter) {
    this.centerPos = posCenter;
    this.centerHistory.add(posCenter);
    this.cache = {};
  }

  shapeRadius() {
    return this.cache.boundaryRadius;
  }

  _pipeInterSectRadius() {
    return this.cache.mainRadius / 2;
  }

  _mainRadius() {
    return this.cache.mainRadius;
  }

  _mainThickness() {
    return this.cache.mainThickness;
  }

  _drawImage(p: TableP5) {
    if (this.image != null) {
      const center = this.center();

      p.push();
      {
        p.translate(center.x, center.y);
        p.image(
          this.image,
          -this.imageSize * 0.5,
          -this.imageSize * 0.5,
          this.imageSize,
          this.imageSize
        );
      }

      p.pop();
    }
  }

  _drawText(p: TableP5) {
    const electricityMW =
      this.node.heatingElectricityCostMW + this.node.coolingElectricityCostMW;

    const textAreaWidth = drawNodeText(
      p,
      this.center(),
      this.name,
      this.node.getHeatingNeedMW(),
      this.node.getCoolingNeedMW(),
      electricityMW,
      this.node._scale,
      this.cache.crustRadius,
      circleNodeTextInset,
      this.context.debug ? 'Size: ' + this.node.getTotalEnergySize() : null
    );
    this.sliderButton.bounding.w = textAreaWidth;
  }

  _drawCrust(p: TableP5) {
    const center = this.center();
    const crustRadius = this.cache.crustRadius;
    const crustThickness = this.cache.crustThickness;

    // Draw the dashed circle around the object
    p.push();
    {
      p.fill(Settings.backgroundColor);
      p.circle(center.x, center.y, crustRadius);

      if (this.size !== 1.0) {
        p.noFill();
        p.strokeCap('butt');
        p.stroke(p.color('white'));

        p.strokeWeight(crustThickness);
        const dashes = 64;

        for (let i = 0; i < dashes; i++) {
          const a1 = (i * 2 * Math.PI) / dashes;
          const a2 = ((i * 2 + 1) * Math.PI) / dashes;

          p.arc(center.x, center.y, crustRadius, crustRadius, a1, a2);
        }
      }
    }
    p.pop();
  }

  _drawMainCircle(p: TableP5) {
    const center = this.center();
    const mainThickness = this._mainThickness();
    const mainRadius = this._mainRadius();

    // Main circle
    p.push();
    {
      p.strokeWeight(mainThickness);
      p.noFill();
      p.strokeCap('butt');

      const energyRatio = this.node.getRatio();
      let endWarm;

      let primaryColor;
      let secondColor;

      if (energyRatio) {
        if (energyRatio.heatRatio === 1) {
          primaryColor = p.color(Settings.warmColor);
        } else if (energyRatio.heatRatio === 0) {
          primaryColor = p.color(Settings.coldColor);
        } else {
          primaryColor = p.color(Settings.warmColor);
          secondColor = p.color(Settings.coldColor);

          endWarm = energyRatio.heatRatio * Math.PI * 2;
        }
      } else {
        primaryColor = p.color('white');
      }

      if (!secondColor) {
        p.stroke(primaryColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, 0, Math.PI * 2);
      } else {
        p.stroke(primaryColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, 0, endWarm);

        p.stroke(secondColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, endWarm, Math.PI * 2);
      }

      if (this.active) {
        p.push();
        p.noStroke();
        p.fill(255, 255, 255, 40);
        p.arc(
          center.x,
          center.y,
          mainRadius - mainThickness,
          mainRadius - mainThickness,
          0,
          Math.PI * 2
        );
        p.pop();
      }
    }
    p.pop();
  }

  _drawPipes(p5Instance: TableP5) {
    p5Instance.push();
    {
      if (this.next) {
        if (this.next.node) {
          const connectionToChildNode = this.node.getConnectionToNode(
            this.next.node
          );
          let reverse = this.next.node !== connectionToChildNode.to;
          let magnitude;

          if (connectionToChildNode.energy < 0) {
            reverse = !reverse;
          }
          magnitude = Math.abs(connectionToChildNode.energy) * 0.4;

          // draw the pipes. In this call we also evaluate what direction the flow is in
          this.warmPipe && this.warmPipe.draw(!reverse, magnitude);
          this.coldPipe && this.coldPipe.draw(reverse, magnitude);
        }
      }
    }
    p5Instance.pop();
  }

  drawBg(p5Instance: TableP5) {
    this.layoutPipes();
    this._drawPipes(p5Instance);
  }

  layout(p5Instance: TableP5) {
    this.buttons.active = this.active;

    // increase threshold every second to avoid jitter caused by mistake
    if (
      p5Instance.frameCount % Settings.fps === 0 &&
      this.moveThreshold < Settings.moveThreshold
    ) {
      this.moveThreshold++;
    }

    let settingsSize = Settings.structureDialSize * this.baseScale;
    let baseSize = Settings.baseStructureSize * this.baseScale;

    const thickness = this.getSize() * baseSize;
    const crustThickness = 0.02 * baseSize;

    // Calculate the animation for the dial with the help from D3
    let pieChartSize = baseSize + thickness;
    let outershell = 3 * baseSize + (1 - crustThickness * 2);

    pieChartSize += settingsSize;
    outershell += settingsSize;

    const t = p5Instance.frameCount - this.createdTimeStamp;

    this.cache.rawSize = outershell;

    if (t < Settings.structureAnimationTime) {
      const animation = d3.easeElasticOut(
        p5Instance.map(t, 0, Settings.structureAnimationTime, 0, 1)
      );
      pieChartSize *= animation;
      outershell *= animation;
      this.imageSize = Settings.structureImageSize * animation;
    } else {
      this.imageSize = Settings.structureImageSize;
    }

    this.cache.mainRadius = pieChartSize;
    this.cache.crustRadius = outershell;
    this.cache.mainThickness = thickness;
    this.cache.crustThickness = crustThickness;

    const center = this.center();

    this.sliderButton.bounding.x = center.x + outershell / 2;
    this.sliderButton.bounding.y = center.y + 1;

    if (this.fixedSize) {
      this.cache.boundaryRadius = this.cache.rawSize / 2;
    } else {
      this.cache.boundaryRadius = this.cache.rawSize / 2;
    }

    // const [transformedX, transformedY] = this.transformedPoint(testCenter.x, testCenter.y);
    const rect = this.closeButton.defaultRect;
    this.closeButton.setDefault({
      ...rect,
      x: center.x - rect.width / 2 + outershell / 2.0 + 30,
      y: center.y - rect.height / 2 + outershell / 2.0 - 30
    });
  }

  _drawMainShape(p) {
    this._drawMainCircle(p);
  }

  /**
   * @throws {String} Message about dead structure
   */
  draw(p: TableP5) {
    if (this.dead) throw 'Trying to access dead Structure';

    p.push();
    {
      if (this.active) {
        this.buttons.draw(p);
      }

      this._drawText(p);

      this._drawCrust(p);

      this._drawMainShape(p);
      this._drawImage(p);
    }
    p.pop();
  }
}

export default Structure;
