import io from 'socket.io-client';
import Performance from '../performance';
import * as DataManager from './data/data_manager';
import SocketMessages from '../../../server/socket-messages';
import { TableContextType } from 'js/Table/context';
import Structure from 'js/Table/Structure';
import EctotableEctoplannerGrid from 'js/Table/EctotableEctoplannerGrid';
import Grid, {
  HistoricEnergyNeed,
  PredictedEnergyNeed
} from 'js/Table/energy_grid';

const statusUpdateInterval = 500; // Never send updates of status more often than every half second

export type EnergyData = {
  location: {
    city: string;
    longitude: number;
    latitude: number;
    zone: string;
  };
  totalEnergyNeed: number;
  totalHeatingNeedMW: number;
  totalCoolingNeedMW: number;
  structures: any[];
  heatingNeedPerDayPerHour: number[];
  coolingNeedPerDayPerHour: number[];
  heatingNeedPerMonth: number[];
  coolingNeedPerMonth: number[];
  totalEnergyHistoric: HistoricEnergyNeed[];
  totalEnergyPredictive: PredictedEnergyNeed[];

  totalAddedEffectSystemMW: number;
  balancedEnergyMW: number;
  unbalancedEnergyMW: number;

  buildingDemandHeatPeakKW: number;
  buildingDemandCoolPeakKW: number;
  buildingDemandHeatTotMWha: number;
  buildingDemandCoolTotMWha: number;
  buildingImportHeatPeakKW: number;
  buildingImportCoolPeakKW: number;
  buildingImportHeatTotMWha: number;
  buildingImportCoolTotMWha: number;
  buildingImportElecPeakKW: number;
  buildingImportElecTotKW: number;
  ehLoadHeatTotMWha: number;
  ehCoolTotMWha: number;
  buildingsBalancedLoadMWha: number;
};

/**
 * The object responsible for sending information from the table to the server
 * @class DataTransmitter
 */
export default class DataTransmitter {
  context: TableContextType;
  timeStampLastData: number;
  lastDataMarker: number;
  socket: any;

  constructor(context, socket) {
    this.timeStampLastData = -100000000000;

    this.context = context;
    this.lastDataMarker = this.context.appNow();

    this.socket =
      socket ||
      io(window.location.origin, {
        query: {
          role: 'table'
        },
        credentials: 'include'
      });
  }

  _convertDataStructureToSendStructure(
    dataStructures: Structure[],
    sendStructures
  ) {
    for (let i = 0; i < dataStructures.length; i++) {
      let s = dataStructures[i];

      const sendStructure = {
        name: s.name,
        id: s.id,
        fingerPrint: s.templateFingerPrint,
        heatingNeedMW: s.getHeatingNeedMW() - s.getExternalGridHeatingNeedMW(),
        coolingNeedMW: s.getCoolingNeedMW() - s.getExternalGridCoolingNeedMW(),
        electricityEffectMW: s.getCurrentElectricityEffectMW(),
        icon: s.icon,
        buildingId: s.buildingId
      };

      sendStructures.push(sendStructure);
    }
  }

  setEctocloudMode(newMode) {
    this.socket.emit(SocketMessages.SET_ECTOCLOUD_MODE, newMode);
  }

  refreshAll() {
    this.socket.emit(SocketMessages.REFRESH_REQUEST);
  }

  setTableState(mainState, subState = undefined, fromTableApp = true) {
    const tableStateObject = {
      mainState: mainState.name,
      fromTableApp: fromTableApp,
      subState: subState
    };

    this.socket.emit(SocketMessages.TABLE_STATE, tableStateObject);
  }

  transmit(
    energyGrid: Grid,
    ectoplannerGrid: EctotableEctoplannerGrid,
    structures,
    location
  ) {
    const performanceObject = { name: 'send status' };

    Performance.startMeasureFor(performanceObject);

    const currentTimestamp = this.context.appNow();

    if (
      structures.dataIsDirtySince(this.lastDataMarker) ||
      ectoplannerGrid.dirty
    ) {
      if (currentTimestamp > this.timeStampLastData + statusUpdateInterval) {
        ectoplannerGrid.markAsClean();
        this.lastDataMarker = this.context.appNow();
        /* The large array summarising needs over every hour the whole year
         */

        let energyStructures: any[] = [];
        const { heating, cooling, monthsHeating, monthsCooling } =
          DataManager.getAnnualNeed(structures.getAllChildren());

        this._convertDataStructureToSendStructure(
          structures.getAllChildren(),
          energyStructures
        );

        const data: EnergyData = {
          location: {
            city: 'Lund',
            longitude: 13.21769,
            latitude: 55.71148,
            zone: 'SE'
          },
          totalEnergyNeed:
            structures.getTotalEnergyNeedMW() -
            structures.getExternalGridTotalEnergyNeedMW(),
          totalHeatingNeedMW:
            structures.getHeatingNeedMW() -
            structures.getExternalGridHeatingNeedMW(),
          totalCoolingNeedMW:
            structures.getCoolingNeedMW() -
            structures.getExternalGridCoolingNeedMW(),
          structures: energyStructures,
          heatingNeedPerDayPerHour: heating,
          coolingNeedPerDayPerHour: cooling,
          heatingNeedPerMonth: monthsHeating,
          coolingNeedPerMonth: monthsCooling,
          totalEnergyHistoric: [],
          totalEnergyPredictive: [],
          totalAddedEffectSystemMW: 0,
          balancedEnergyMW: 0,
          unbalancedEnergyMW: 0,

          buildingDemandHeatPeakKW: 0,
          buildingDemandCoolPeakKW: 0,
          buildingDemandHeatTotMWha: 0,
          buildingDemandCoolTotMWha: 0,
          buildingImportHeatPeakKW: 0,
          buildingImportCoolPeakKW: 0,
          buildingImportHeatTotMWha: 0,
          buildingImportCoolTotMWha: 0,
          buildingImportElecPeakKW: 0,
          buildingImportElecTotKW: 0,
          ehLoadHeatTotMWha: 0,
          ehCoolTotMWha: 0,
          buildingsBalancedLoadMWha: 0
        };

        if (ectoplannerGrid.form?.calculations != null) {
          const calculations = ectoplannerGrid.form.calculations;

          data.buildingDemandHeatPeakKW =
            calculations.allBuildingDemandsSummary.heatPeak;
          data.buildingDemandCoolPeakKW =
            calculations.allBuildingDemandsSummary.coolPeak;
          data.buildingDemandHeatTotMWha =
            calculations.allBuildingDemandsSummary.heatTot;
          data.buildingDemandCoolTotMWha =
            calculations.allBuildingDemandsSummary.coolTot;
          data.buildingImportHeatPeakKW =
            calculations.allBuildingImportsSummary.heatPeak;
          data.buildingImportCoolPeakKW =
            calculations.allBuildingImportsSummary.coolPeak;
          data.buildingImportHeatTotMWha =
            calculations.allBuildingImportsSummary.heatTot;
          data.buildingImportCoolTotMWha =
            calculations.allBuildingImportsSummary.coolTot;
          data.buildingImportElecPeakKW =
            calculations.allBuildingImportsSummary.elecPeak;
          data.buildingImportElecTotKW =
            calculations.allBuildingImportsSummary.elecTot;
          data.ehLoadHeatTotMWha = calculations.ehLoadProfilesSummary.heatTot;
          data.ehCoolTotMWha = calculations.ehLoadProfilesSummary.coolTot;
          data.buildingsBalancedLoadMWha = calculations.netwBalanced.heat * 2.0;
        }

        data.location = location;

        if (energyGrid) {
          /*Electricity*/
          data.totalEnergyHistoric = energyGrid.totalEnergyHistoric;
          data.totalEnergyPredictive = energyGrid.totalEnergyPredictive;
          data.totalAddedEffectSystemMW =
            energyGrid.summary.totalAddedEffectSystemMW;
          data.balancedEnergyMW = energyGrid.summary.balancedEnergyMW;
          data.unbalancedEnergyMW = energyGrid.summary.unbalancedEnergyMW;
        }

        const dataAsString = JSON.stringify(data);

        const performanceObject2 = { name: 'socket emit' };

        Performance.startMeasureFor(performanceObject2);

        this.socket.emit(SocketMessages.DATA_BROADCAST, {
          data: dataAsString
        });

        Performance.stopMeasureFor(performanceObject2);

        this.socket.data = dataAsString;

        this.timeStampLastData = currentTimestamp;
      }
    }
    Performance.stopMeasureFor(performanceObject);
  }
}
