import {
  EctoplannerCalculationResult,
  EctoplannerForm,
  EctoplannerFormBuilding,
  NproPromiseInput
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EctogridCluster from './EctogridCluster';
import PhysicalNode from './physical_node';
import { EctotableLocation } from './Settings';
import Structure from './Structure';
import _ from 'lodash';
import standardBuildingsFormObj from './data/standard_buildings_form.json';

export const standardEctotableBuildingsForm =
  standardBuildingsFormObj as any as EctoplannerForm;

let calculationIdCounter = 0;

const setBuildingTypes = (
  country: string,
  building: EctoplannerFormBuilding,
  cityData: any
) => {
  if (
    cityData?.[building.params.buildingType]?.[
      building.params.buildingSubtype
    ] != null
  ) {
    return;
  }

  // Not used in practice since each building has a predefined heating/cooling profile,
  // and this is only used dynamically calculated profiles. But needs to be set since
  // it is still used to index the city data in the calculation.
  if (country === 'Germany') {
    building.params.buildingType = 'residential';
    building.params.buildingSubtype = 'sfh1918';
  } else if (country === 'Switzerland') {
    building.params.buildingType = 'residential';
    building.params.buildingSubtype = 'sfh1970';
  } else {
    building.params.buildingType = 'residential';
    building.params.buildingSubtype = 'newBuild';
  }

  return building;
};

const scaleBuilding = (building: EctoplannerFormBuilding, scale: number) => {
  const correspondingPlannerBuilding =
    standardEctotableBuildingsForm.buildings.find(
      (x) => x.name === building.name
    );

  if (building.params.spaceHeatProfile != null) {
    building.params.spaceHeatProfile =
      correspondingPlannerBuilding.params.spaceHeatProfile.map(
        (x) => x * scale
      );
  }

  if (building.params.spaceCoolProfile != null) {
    building.params.spaceCoolProfile =
      correspondingPlannerBuilding.params.spaceCoolProfile.map(
        (x) => x * scale
      );
  }

  if (building.params.dhwProfile != null) {
    building.params.dhwProfile =
      correspondingPlannerBuilding.params.dhwProfile.map((x) => x * scale);
  }

  if (building.params.processCoolProfile != null) {
    building.params.processCoolProfile =
      correspondingPlannerBuilding.params.processCoolProfile.map(
        (x) => x * scale
      );
  }

  return building;
};

export const compileFormPromise = ({
  inputForm,
  cityData,
  airTemp,
  calculationIndex
}: NproPromiseInput): Promise<EctoplannerCalculationResult> => {
  const worker = new Worker(
    new URL('./Ectoplanner/EctoplannerWorker.dist.js', import.meta.url),
    {
      type: 'module'
    }
  );
  const idToUse = calculationIdCounter++;

  const promise = new Promise<EctoplannerCalculationResult>(
    (resolve, reject) => {
      worker.onmessage = ({ data: { form, checksum, id, error } }) => {
        if (id === idToUse) {
          if (error != null) {
            reject(error);
          } else {
            resolve({
              form: form as unknown as EctoplannerForm,
              checksum: checksum as string,
              calculationIndex
            });
          }
        }
      };
    }
  );

  worker.postMessage({
    form: inputForm,
    id: idToUse,
    cityData,
    airTemp
  });

  return promise;
};

type PhysicalNodeMapping = {
  node: PhysicalNode;
  buildingIndex: number;
  numberOfBuildings: number;
};

class EctotableEctoplannerGrid {
  form: EctoplannerForm;
  ectoTableFormData: EctoplannerForm;
  airTemp?: string;
  cityData?: any;
  physicalNodes: PhysicalNodeMapping[] = [];
  dirty = false;
  debouncedUpdateForm: () => void;

  constructor() {
    this.form = null;
    this.ectoTableFormData = null;
    this.debouncedUpdateForm = _.debounce(this.updateForm, 200);
  }

  updateForm = async () => {
    const result = await compileFormPromise({
      inputForm: this.form,
      cityData: this.cityData,
      airTemp: this.airTemp,
      calculationIndex: calculationIdCounter++
    });

    this.form = result.form;
    this.dirty = true;
  };

  setEctotableFormData(formData: EctoplannerForm) {
    this.ectoTableFormData = formData;
  }

  markAsClean() {
    this.dirty = false;
  }

  updateLocationData(
    location: EctotableLocation,
    cityData: any,
    airTemp: string
  ) {
    this.form.location = {
      city: {
        name: location.city,
        id: location.weatherStationId,
        file: location.weatherStationId + '.epw'
      },
      country: {
        name: location.country
      }
    };

    this.cityData = cityData;
    this.airTemp = airTemp;

    for (let building of this.form.buildings) {
      setBuildingTypes(location.country, building, this.cityData);
    }
  }

  addItem(item: PhysicalNode) {
    if (item instanceof EctogridCluster) {
      if (this.ectoTableFormData == null) {
        return;
      }

      for (let building of this.ectoTableFormData.buildings) {
        const transformedBuilding = setBuildingTypes(
          this.form.location.country.name,
          _.cloneDeep(building),
          this.cityData
        );

        this.form.buildings.push(transformedBuilding);
      }

      this.physicalNodes.push({
        node: item,
        buildingIndex:
          this.form.buildings.length - this.ectoTableFormData.buildings.length,
        numberOfBuildings: this.ectoTableFormData.buildings.length
      });
      this.updateForm();
      return;
    }

    if (item.buildingId == null) {
      return;
    }

    const correspondingPlannerBuilding =
      standardEctotableBuildingsForm.buildings.find(
        (x) => x.name === item.buildingId
      );

    if (correspondingPlannerBuilding != null) {
      let size = 1.0;

      if (item instanceof Structure) {
        size = item.size;
      }

      this.form.buildings.push(
        scaleBuilding(
          setBuildingTypes(
            this.form.location.country.name,
            _.cloneDeep(correspondingPlannerBuilding),
            this.cityData
          ),
          size
        )
      );

      this.updateForm();
      this.physicalNodes.push({
        node: item,
        buildingIndex: this.form.buildings.length - 1,
        numberOfBuildings: 1
      });
    } else {
      console.log('Could not find building with name', item.buildingId);
    }
  }

  removeItem(item: PhysicalNode) {
    const buildingMapping = this.physicalNodes.find((x) => x.node === item);

    if (buildingMapping != null) {
      this.physicalNodes = this.physicalNodes.filter(
        (node) => node !== buildingMapping
      );
      this.form.buildings.splice(
        buildingMapping.buildingIndex,
        buildingMapping.numberOfBuildings
      );
      this.updateForm();
    }
  }

  onScaleChanged = (item: PhysicalNode) => {
    if (item instanceof Structure) {
      const buildingMapping = this.physicalNodes.find((x) => x.node === item);
      if (buildingMapping) {
        this.form.buildings[buildingMapping[1]] = scaleBuilding(
          this.form.buildings[buildingMapping.buildingIndex],
          item.size
        );
      } else {
        console.error('Unable to find corresponding building');
      }
    }

    this.debouncedUpdateForm();
  };
}

export default EctotableEctoplannerGrid;
