import EnergyNode from 'js/Table/energy_node';
import Util from './util';
import Connection from 'js/Table/energy_connection';
import _ from 'lodash';

const numberOfHoursToUse = 24;

export type HistoricEnergyNeed = {
  time: number;
  totalElectricityNeeded: number;
  electricitySupplied: number;
};

export type PredictedEnergyNeed = {
  time: number;
  predictedTotalElectricityNeeded: number;
  predictedElectricitySupplied: number;
};

export class EnergyGridSummary {
  nodes: EnergyNode[];
  connections: Connection[];
  heating: number;
  heatingWithoutExternalSources: number;
  cooling: number;
  coolingWithoutExternalSources: number;
  total: number;
  heatingSatisfaction: number;
  coolingSatisfaction: number;

  totalAddedEffectSystemMW: number;
  balancedEnergyMW: number;
  unbalancedEnergyMW: number;
  forceMotion: boolean;

  reset() {
    (this.nodes = []), (this.connections = []);
    this.heating = 0;
    this.heatingWithoutExternalSources = 0;
    this.cooling = 0;
    this.coolingWithoutExternalSources = 0;
    this.total = 0;
    this.heatingSatisfaction = 0;
    this.coolingSatisfaction = 0;
    this.totalAddedEffectSystemMW = 0;
    this.balancedEnergyMW = 0;
    this.unbalancedEnergyMW = 0;
  }

  constructor(forceMotion: boolean = false) {
    this.reset();
    this.forceMotion = forceMotion;
  }

  summarise(): void {
    this.total = this.heating - this.cooling;

    if (this.total === 0) {
      this.heatingSatisfaction = 1;
      this.coolingSatisfaction = 1;
    } else if (this.total > 0) {
      this.heatingSatisfaction = this.cooling / this.heating;
      this.coolingSatisfaction = 1;
    } else {
      this.coolingSatisfaction = this.heating / this.cooling;
      this.heatingSatisfaction = 1;
    }

    if (this.forceMotion) {
      this.heatingSatisfaction = Math.max(0.005, this.heatingSatisfaction);
      this.coolingSatisfaction = Math.max(0.005, this.coolingSatisfaction);
    }
  }
}

/**
 * The Grid is an abstract representation of energy needs in Nodes and
 * the transportation of energy between Nodes via Connections.
 * The grid doesn't know anything about distances or rendering
 *
 * @class Grid
 */
export default class Grid {
  totalHeatingCost: number;
  totalCoolingCost: number;
  totalEnergyHistoric: HistoricEnergyNeed[];
  totalEnergyPredictive: PredictedEnergyNeed[];
  nodes: EnergyNode[];
  connections: Connection[];
  summary: EnergyGridSummary;
  referenceDate = new Date(Date.now());

  /**
   *
   * @param forceMotion If set, there will always be some heat / cooling transfer, even if * there is no demand, to keep the visuals interesting.
   */
  constructor(forceMotion: boolean = false) {
    this.summary = new EnergyGridSummary(forceMotion);
    this.reset();
  }

  _expandFromNode(startNode: EnergyNode, nodes, connections) {
    nodes.push(startNode);
    const startConnections = startNode.connections;

    startConnections.forEach((connection) => {
      if (connections.indexOf(connection) === -1) {
        connections.push(connection);
        const otherNode = connection.getNode(startNode);

        if (nodes.indexOf(otherNode) === -1) {
          this._expandFromNode(otherNode, nodes, connections);
        }
      }
    });
  }

  summarise(startNode: EnergyNode): EnergyGridSummary {
    this.summary.reset();

    if (startNode == null) {
      return this.summary;
    }

    this._expandFromNode(
      startNode,
      this.summary.nodes,
      this.summary.connections
    );

    this.summary.nodes.forEach((node) => {
      this.summary.heating += node.getHeatingNeedMW();
      this.summary.cooling += node.getCoolingNeedMW();

      if (!node.outsideGrid) {
        this.summary.heatingWithoutExternalSources += node.getHeatingNeedMW();
        this.summary.coolingWithoutExternalSources += node.getCoolingNeedMW();
      }
    });

    this.summary.summarise();

    return this.summary;
  }

  _prepareHistoryObject(numberOfHours: number) {
    const hours: HistoricEnergyNeed[] = [];
    const hour = this.referenceDate.getHours();

    for (let hoursAgo = numberOfHours; hoursAgo >= 0; hoursAgo--) {
      hours.push({
        time: (24 + hour - hoursAgo) % 24,
        totalElectricityNeeded: 0,
        electricitySupplied: 0
      });
    }

    return hours;
  }

  _preparePredictiveObject(numberOfHours: number) {
    const hours: PredictedEnergyNeed[] = [];
    const hour = this.referenceDate.getHours();

    for (let hoursForward = 0; hoursForward <= numberOfHours; hoursForward++) {
      hours.push({
        time: (hour + hoursForward) % 24,
        predictedTotalElectricityNeeded: 0,
        predictedElectricitySupplied: 0
      });
    }

    return hours;
  }

  distribute(summary: EnergyGridSummary) {
    const leaves = [];
    const dirtyNodes = [];
    const dirtyConnections = {};
    const energyBroughtToNode = {};

    this.totalHeatingCost = 0;
    this.totalCoolingCost = 0;

    this.totalEnergyHistoric = this._prepareHistoryObject(numberOfHoursToUse);
    this.totalEnergyPredictive =
      this._preparePredictiveObject(numberOfHoursToUse);

    const heatingSatisfaction = summary.heatingSatisfaction;
    const coolingSatisfaction = summary.coolingSatisfaction;

    const heatingUnsatisfaction = 1 - heatingSatisfaction;
    const coolingUnsatisfaction = 1 - coolingSatisfaction;

    let totalCoolingNeedMW = 0;
    let totalHeatingNeedMW = 0;

    // I assume this is a COP based calculation?
    const getHeatingElectricityCostMW = (heatNeedMW) =>
      heatNeedMW * heatingUnsatisfaction * (1 / 4) +
      heatNeedMW * heatingSatisfaction * (1 / 8);
    const getCoolingElectricityCostMW = (coldNeedMW) =>
      coldNeedMW * coolingUnsatisfaction * (1 / 3) +
      coldNeedMW * coolingSatisfaction * (1 / 6);

    summary.nodes.forEach((node) => {
      const nodeHeatingNeedMW = node.getHeatingNeedMW();
      const nodeCoolingNeedMW = node.getCoolingNeedMW();

      node.heatingElectricityCostMW =
        getHeatingElectricityCostMW(nodeHeatingNeedMW);
      node.coolingElectricityCostMW =
        getCoolingElectricityCostMW(nodeCoolingNeedMW);

      totalCoolingNeedMW += nodeCoolingNeedMW;
      totalHeatingNeedMW += nodeHeatingNeedMW;

      const nodeHistoric = node.getHistoric();
      const nodePredictive = node.getPredictive();

      // Traverse each hour and sum the numbers.
      for (let i = 0; i < nodeHistoric.length; i++) {
        const energyCost =
          getHeatingElectricityCostMW(nodeHistoric[i].heatingNeedMW) +
          getCoolingElectricityCostMW(nodeHistoric[i].coolingNeedMW);
        const predictedEnergyCost =
          getHeatingElectricityCostMW(nodePredictive[i].heatingNeedMW) +
          getCoolingElectricityCostMW(nodePredictive[i].coolingNeedMW);

        if (node.electricityNode != null) {
          this.totalEnergyHistoric[i].electricitySupplied +=
            nodeHistoric[i].electricityGenerationMW;
          this.totalEnergyPredictive[i].predictedElectricitySupplied +=
            nodePredictive[i].electricityGenerationMW;
        }

        if (!node.outsideGrid) {
          this.totalEnergyHistoric[i].totalElectricityNeeded += energyCost;
          this.totalEnergyPredictive[i].predictedTotalElectricityNeeded +=
            predictedEnergyCost;
        }
      }

      // console.log(node.id.toString().padStart(3, '0'), energySum);

      if (!node.outsideGrid) {
        this.totalHeatingCost += node.heatingElectricityCostMW;
        this.totalCoolingCost += node.coolingElectricityCostMW;
      }

      energyBroughtToNode[node.id] = 0;

      if (node.connections.length > 0) {
        if (node.connections.length === 1) {
          leaves.push(node);
        }

        dirtyNodes.push(node);
        dirtyConnections[node.id] = node.connections.slice();
      }
    });

    // console.log(
    //   _.maxBy(this.totalEnergyHistoric, (elem) => elem.totalElectricityNeeded)
    //     ?.totalElectricityNeeded
    // );
    // Perform the ectotable balancing algorithm. I think this is really non-intuitive, but here goes.
    const assumedCOPHeating = 5;
    const assumedCOPCooling = 4;
    const electricityInputNeededCoolingMW =
      totalCoolingNeedMW / assumedCOPCooling;
    const electricityInputNeededHeatingMW =
      totalHeatingNeedMW / assumedCOPHeating;
    const totalElectricityInputMW =
      electricityInputNeededCoolingMW + electricityInputNeededHeatingMW;

    // Yes, this is part of why it is confusing. For some reason we subtract the electricity input from the total heating need,
    // but add it to the total cooling need.
    const heatNeedMW = totalHeatingNeedMW - electricityInputNeededHeatingMW;
    const coolingNeedMW = totalCoolingNeedMW + electricityInputNeededCoolingMW;

    const diffNeedMW = Math.abs(heatNeedMW - coolingNeedMW);

    summary.totalAddedEffectSystemMW = totalElectricityInputMW + diffNeedMW;
    summary.balancedEnergyMW = Math.min(heatNeedMW, coolingNeedMW);
    summary.unbalancedEnergyMW =
      totalCoolingNeedMW +
      electricityInputNeededCoolingMW +
      electricityInputNeededHeatingMW +
      heatNeedMW;

    const _stabiliseChain = (leaf) => {
      let node = leaf as EnergyNode;

      // Walk throught the whole chain until we reach a leaf again on the other side
      while (dirtyNodes.length && node !== undefined) {
        let nextConnection = dirtyConnections[node.id][0];

        // What to push forward
        const heatToRecieveNextConnection =
          node.getHeatingNeedMW() * summary.heatingSatisfaction -
          node.getCoolingNeedMW() * summary.coolingSatisfaction +
          energyBroughtToNode[node.id];

        nextConnection.setValue(heatToRecieveNextConnection);
        if (nextConnection.to === node) {
          nextConnection.energy *= -1;
        }

        // Pick up the node on the other side of the connection
        const nextNode = nextConnection.getNode(node);

        energyBroughtToNode[node.id] -= heatToRecieveNextConnection;
        energyBroughtToNode[nextNode.id] += heatToRecieveNextConnection;

        // Remove the connection from the dirty connection of both nodes
        Util.arrayRemove(dirtyConnections[node.id], nextConnection);
        Util.arrayRemove(dirtyConnections[nextNode.id], nextConnection);

        // If the Node now has been through all it's connections, it's clean
        if (dirtyConnections[node.id].length === 0) {
          Util.arrayRemove(dirtyNodes, node);
        }

        // If the Node after this still has one and only one connection, we can treat it as a leaf
        if (dirtyConnections[node.id].length === 1) {
          leaves.push(node);
        }

        // If the next Node doesn't have any more connections then we don't have to deal with it, it's clean
        if (dirtyConnections[nextNode.id].length === 0) {
          Util.arrayRemove(dirtyNodes, nextNode);
          Util.arrayRemove(leaves, nextNode);
        }

        if (dirtyConnections[nextNode.id].length !== 1) {
          break;
        }

        node = nextNode;
      }
    };

    while (leaves.length > 0) {
      const leaf = leaves.shift();

      _stabiliseChain(leaf);
    }
  }

  reset() {
    this.totalHeatingCost = 0;
    this.totalCoolingCost = 0;
    this.totalEnergyHistoric = [];
    this.totalEnergyPredictive = [];
    this.nodes = [];
    this.connections = [];
    this.summary.reset();

    this.nodes = [];
  }

  setReferenceDate(date: Date) {
    this.referenceDate = date;
  }
}
